// Breakpoints ----------------------------------------------------------------

// Mixin to manage responsive breakpoints
// @author Kitty Giraudel
// @param {String} $breakpoint - Breakpoint name
// @require $breakpoints
@mixin breakpoint($breakpoint) {
  // if the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // prints a media query based on the value
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // if the key doesn't exist in the map
  @else {
    @warn "unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin up-to-breakpoint($breakpoint) {
  // if the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // prints a media query based on the value
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  // if the key doesn't exist in the map
  @else {
    @warn "unfortunately, no value could be retrieved from `#{$breakpoint}`. "
      + "available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
