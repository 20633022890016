@font-face {
  font-family: "Pinup";
  src: local("Pinup"), url(../fonts/Pinup.woff2) format("woff2");
  font-weight: normal;
  font-style: normal
}

@font-face {
  font-family: 'Andes';
  src: url('../fonts/subset-Andes.woff2') format('woff2');
  font-weight: normal;
  font-style: normal
}
